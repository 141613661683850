import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const ZapisMeropriyatij = () => {
  return (
    <>
      <Helmet>
        <title>Запись мероприятий стоимость в Москве услуги от Elandic</title>
        <meta name="description" content="Запись мероприятий цена в Москве услуги под ключ на сайте студии Elandic. Заказать услуги репортажной съемки мероприятий стоимость от видеопродакшена." />
        <meta name="keywords" content="запись мероприятий, съемка, видео, репортажная, москва, стоимость, цена" />
      </Helmet>
      <MainPage>
        <SeoText title='Запись мероприятий'>
          <p>Ваше мероприятие - это уникальный момент, который хочется сохранить в памяти и поделиться с миром. Неважно, это свадьба, корпоратив, конференция, концерт или любое другое событие - видеозапись поможет вам пережить его снова и снова, а также станет прекрасным инструментом для продвижения бренда или личного контента.</p>

          <p>Видеопродакшн Elandic - это команда профессиональных операторов и монтажеров, которая специализируется на качественной съемке мероприятий в Москве. Мы предлагаем репортажную съемку, динамичную и живую запись события, съемку с несколькими камерами.</p>

          <p>Мы работаем в сфере видеопродакшена уже много лет и имеем богатый опыт работы с различными типами мероприятий. Мы используем профессиональное оборудование, разрабатываем индивидуальные сценарии и подходы к съемке, учитывая ваши пожелания и особенности мероприятия. Мы предлагаем конкурентные цены на услуги видеопродакшена, учитывая ваши финансовые возможности. Мы соблюдаем сроки и предоставляем гарантии на качество работы.</p>

          <p>Стоимость наших услуг зависит от нескольких факторов, в том числе: длительности мероприятия, сложности задачи, количества камер, необходимости дополнительных услуг (графика, анимация, озвучка и т.д.).</p>

          <p>Чтобы узнать точную стоимость вашего проекта, свяжитесь с нами через форму на сайте или по телефону.</p>

          <p>Сохраните ваше мероприятие с помощью съемки от видеопродакшена Elandic! Мы гарантируем высокое качество видео, отличное обслуживание и доступные цены. Свяжитесь с нами сегодня, чтобы узнать подробнее о наших услугах и получить бесплатную консультацию.</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(ZapisMeropriyatij);
